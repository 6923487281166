import store from "@/store";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";

import { ErrorResponse } from "@/services/axios/error";
import isEmpty from "lodash/isEmpty";
import { FormDataAPI } from "@/services/formData";
import { IContactSJDTO } from "@/models/FormData";

const name = "GreenOptionsModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class GreenOptionsModule extends VuexModule {
  private error = {} as ErrorResponse;
  private errorCarbonEmission = {} as ErrorResponse;
  private dataContractSJ = {} as any;
  private isLoadingContractSJ = false;

  get isError() {
    return !isEmpty(this.error);
  }

  get dataContractSJGetter() {
    return this.dataContractSJ;
  }

  @Mutation
  loadingContractSJ() {
    this.isLoadingContractSJ = true;
  }

  @Mutation
  contractSJError(error: ErrorResponse) {
    this.errorCarbonEmission = error;
    this.isLoadingContractSJ = false;
  }

  @Mutation
  contractSJSuccess(data: any) {
    this.dataContractSJ = data;
    this.isLoadingContractSJ = false;
    this.errorCarbonEmission = {} as ErrorResponse;
  }

  @Action
  async submitContractSJ(payload: IContactSJDTO) {
    this.loadingContractSJ();
    const serviceFormDataAPI = new FormDataAPI();
    const { data, error } = await serviceFormDataAPI.submitContractSJ(payload);
    if (error) {
      this.contractSJError(error);
    } else {
      this.contractSJSuccess(data);
    }
  }
}

export default getModule(GreenOptionsModule);
